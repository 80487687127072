import { ConnectWallet } from "@thirdweb-dev/react";
import "./styles/Home.css";

export default function Home() {
  return (
    <main className="main">
      <div className="container">
        <div className="header">
          <h1 className="title">
            Welcome to the new{" "}
            <span className="gradient-text-0">
              <a
                href="https://salientyachts.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                KINGCAT.
              </a>
            </span>
          </h1>

          <p className="description">
            New Manufacturing Techniques <br />
            New Styling and Moulds<br />
            Better Features<br />
          </p>
        </div>


        <div className="grid">
          <a
            href="https://salientyachts.com/"
            className="card"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/top-corner.png"
              alt="Placeholder preview of starter"
            />
            <div className="card-text">
              <h2 className="gradient-text-1">Built by  ➜</h2>
              <p>
                Salient Yachts
              </p>
            </div>
          </a>

        </div>
      </div>
    </main>
  );
}
